import React, { useState } from 'react'

// Components
import LandingPage from '../../components/shared/LandingPage'
import MagneticTooltipGsap from '../../components/shared/MagneticTooltipGsap'
import MacyMasonry from '../../components/shared/MacyMasonry'
import NextPrevModalLightbox from '../../components/shared/NextPrevModalLightbox'

const componentsMap = {
    landing: LandingPage,
    magneticTooltip: MagneticTooltipGsap,
    macyMasonry: MacyMasonry,
    nextPrevModalLightbox: NextPrevModalLightbox
};
const buttonTexts = {
    landing: 'Landing Page',
    magneticTooltip: 'Magnetic Tooltip',
    macyMasonry: 'Macy Masonry',
    nextPrevModalLightbox: 'Next & Prev Lightbox'
};

const Homepage = () => {
    const [activeComponent, setActiveComponent] = useState('landing');
    const ComponentToRender = componentsMap[activeComponent];

    return (
        <React.Fragment>
            <div className="flex flex-row flex-wrap justify-start items-start gap-2 mx-6 my-6">
                {Object.keys(componentsMap).map((key) => (
                    <button
                        key={key}
                        onClick={() => setActiveComponent(key)}
                        className={`inline-flex font-semibold tracking-[-0.02em] text-[13px]  px-4 py-2 rounded-md transition-all duration-[0.2s] ${activeComponent === key ? 'text-blue-600 bg-blue-100' : 'bg-gray-100 text-gray-600 hover:bg-gray-400 hover:text-white'}`}
                    >
                        {buttonTexts[key]}
                    </button>
                ))}
            </div>

            
            <ComponentToRender />
        </React.Fragment>
    )
}

export default Homepage

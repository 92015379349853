import React from "react"
import PropTypes from "prop-types"

const Icons = ({ className, nameIcon }) => {
    const getIconName = () => {
        switch (nameIcon) {
            case "close": 
                return (
                    <svg viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  strokeWidth="2"  strokeLinecap="round"  strokeLinejoin="round" className={className}><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M18 6l-12 12" /><path d="M6 6l12 12" /></svg>
                )
            case "chevron-left":
                return (
                    <svg viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  strokeWidth="2"  strokeLinecap="round"  strokeLinejoin="round"  className={className}><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M15 6l-6 6l6 6" /></svg>
                )
            case "chevron-right":
                return (
                    <svg viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  strokeWidth="2"  strokeLinecap="round"  strokeLinejoin="round"  className={className}><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M9 6l6 6l-6 6" /></svg>
                )
            default:
                return ""
        }
    }

    return getIconName();
}

Icons.propTypes = {
    className: PropTypes.string,
    nameIcon: PropTypes.oneOf([
        'close',
        'chevron-right', 
        'chevron-left', 
    ]).isRequired,
}

export default Icons
